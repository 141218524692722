html {
  --inset-top: env(safe-area-inset-top);
  --inset-right: env(safe-area-inset-right);
  --inset-bottom: env(safe-area-inset-bottom);
  --inset-left: env(safe-area-inset-left);
  --fancy-font: "Bungee";
  --sans-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color-scheme: dark;
}

html,
body {
  background: black;
  transition: background-color 1s;
}

body {
  font-family: var(--fancy-font), var(--sans-font);
}

button {
  -webkit-tap-highlight-color: transparent;
}

.Game {
  --scale: 1;
  --button-size: calc(3.5rem * var(--scale));
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: grid;
  place-content: center;
  font-size: calc(1rem * var(--scale));
  color: white;
}

.frame {
  position: relative;
  border: 0.125rem solid rgba(0, 0, 0, 0.6);
  border-radius: calc(2rem * var(--scale));
  overflow: hidden;
  transition: background-color 1s, transform 0.25s;
}

nav,
section,
canvas {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

canvas {
  transition: filter 0.25s, opacity 0.25s;
}

canvas.inactive {
  filter: blur(1rem);
  opacity: 0.15;
}

header.gameName {
  font-size: 5em;
  position: absolute;
  left: 0;
  right: 0;
  top: 1.5em;
  width: 100%;
  line-height: 1;
}

header.gameName::before,
header.gameName::after {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  display: block;
  text-align: center;
  content: "Hexyl";
}

header.gameName::before {
  text-shadow: 0 0.05em 0 rgba(255, 255, 255, 0.6);
}

header.gameName::after {
  background: var(--waves-pattern);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

nav,
section {
  padding: calc(0.5rem * var(--scale));
}

nav {
  height: var(--button-size);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.iconButton {
  position: relative;
  display: block;
  width: var(--button-size);
  height: var(--button-size);
  line-height: var(--button-size);
  text-align: center;
  font: var(--fa-font-solid);
  border: none;
  outline: none;
  background: none;
  color: inherit;
  cursor: pointer;
  transition: transform 0.1s;
}

.iconButton::before {
  font-size: 2em;
  content: attr(data-icon);
}

.iconButton:hover {
  transform: scale(1.125);
}

.level {
  padding-inline: 0.75em;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1;
  opacity: 0.6;
  user-select: none;
}

.levelText {
  font-size: 0.5em;
}

.levelNumber {
  font-size: 1.5em;
}

.credits {
  position: relative;
  text-decoration: none;
}

.credits .icon {
  position: absolute;
  inset: 0;
  display: grid;
  place-content: center;
}

.credits .icon::before {
  font-size: 2em;
  content: "\f1f9";
}

/* .credits .letter.j {
  transform: translate(0%, 0%) scale(1);
} */

.credits .letter.j::before {
  content: "j";
}

.settingsMenu .iconButton,
.pauseMenu .iconButton {
  position: relative;
  z-index: 10;
}

section.biomeList {
  display: flex;
  padding: 0;
  transform: none;
  transition: transform 0.5s;
}

.biome {
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.biome .visual {
  --size: 1em;
  position: relative;
  width: var(--size);
  height: var(--size);
  font: var(--fa-font-solid);
  text-align: center;
  font-size: 14em;
  animation: rotateVertical3d 4s ease-in-out infinite alternate;
}

.biome .visual > div {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-content: center;
  transform-origin: 50% 50% 0;
}

.biome .visual .solid-layer,
.biome .visual .pattern-layer {
  animation: rotateHorizontal3d 5s ease-in-out infinite alternate-reverse;
}

.biome .visual .solid-layer {
  text-shadow: 0 0.02em 0 rgba(255, 255, 255, 0.6);
}

.biome .visual .pattern-layer {
  background: var(--waves-pattern);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.biome .visual .solid-layer::before,
.biome .visual .pattern-layer::before {
  content: "\f312";
}

.biome .visual .icon-layer {
  font-size: 0.5em;
  color: var(--background);
  text-shadow: 0 0.05em 0.02em rgba(0, 0, 0, 0.2);
  animation: wobble3d 3s ease-in-out infinite alternate;
  transition: color 1s;
}

.biome .visual .icon-layer::before {
  content: attr(data-icon);
}

@keyframes rotateHorizontal3d {
  from {
    transform: perspective(500px) rotateY(-15deg);
  }

  to {
    transform: perspective(500px) rotateY(15deg);
  }
}

@keyframes rotateVertical3d {
  from {
    transform: perspective(500px) rotateX(-15deg);
  }

  to {
    transform: perspective(500px) rotateX(15deg);
  }
}

@keyframes wobble3d {
  from {
    transform: perspective(500px) translateZ(0) rotate3d(1, 1, 1, -6deg);
  }

  to {
    transform: perspective(500px) translateZ(-0.5em) rotate3d(1, 1, 1, 6deg);
  }
}

.biome header {
  font-size: 3em;
  line-height: 1;
  position: relative;
  width: 100%;
}

.biome header::before,
.biome header::after {
  position: absolute;
  left: 0;
  top: 0.75em;
  right: 0;
  display: block;
  text-align: center;
  content: attr(data-name);
}

.biome header::before {
  text-shadow: 0 0.05em 0 rgba(255, 255, 255, 0.6);
}

.biome header::after {
  background: var(--waves-pattern);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.biomeArrows {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.biomeArrows button {
  height: 50%;
  transition: transform 0.5s, opacity 0.5s;
}

.biomeArrows button:hover {
  transform: scale(1.5);
}

.biomeArrows button:disabled {
  transform: scale(0);
  opacity: 0;
}

.biomeArrows button:first-child:disabled {
  transform: translateX(-100%) scale(0.5);
}

.biomeArrows button:last-child:disabled {
  transform: translateX(100%) scale(0.5);
}

.bottomActions {
  top: unset;
  display: flex;
  justify-content: center;
  padding-bottom: 5em;
  z-index: 20;
}

.enterBiome {
  padding: 0.5em 1em;
  font-family: inherit;
  font-size: 2em;
  font-weight: bold;
  line-height: 1;
  /* text-transform: uppercase; */
  border: none;
  border-radius: 10ex;
  text-shadow: 0 0.1em 0.05em rgba(0, 0, 0, 0.2);
  /* box-shadow: 0 0 0.25em white; */
  box-shadow: 0 0.1em 0 0 rgba(255, 255, 255, 0.6);
  background: white var(--waves-pattern);
  color: var(--background);
  cursor: pointer;
  transition: transform 0.1s;
}

.enterBiome:hover,
.enterBiome:focus {
  transform: scale(1.1);
}

.enterBiome:active {
  box-shadow: none;
  transform: none;
}

section.menuList {
  display: grid;
  place-content: center;
  gap: 2em;
  line-height: 1;
}

section.menuList > * {
  font-size: 2em;
  animation: float-in 0.25s;
  animation-duration: calc(0.25s + var(--index, 1) * 0.1s);
}

@keyframes float-in {
  0% {
    opacity: 0;
    transform: translateY(2em);
  }

  50% {
    opacity: var(--opacity, 1);
  }

  100% {
    transform: none;
  }
}

section.menuList button {
  font: inherit;
  font-size: 2.5em;
  border: none;
  background: none;
  color: inherit;
  text-shadow: 0 0.05em 0 rgba(255, 255, 255, 0.6);
  text-transform: capitalize;
  cursor: pointer;
  transition: transform 0.1s;
}

section.menuList button.disabled {
  --opacity: 0.6;
  position: relative;
  opacity: var(--opacity);
}

section.menuList button.disabled::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -0.05em;
  height: 0.1em;
  background: currentColor;
  opacity: 0.75;
  transform: rotate(5deg);
  transition: inherit;
  content: "";
}

section.menuList button:hover {
  opacity: 1;
  transform: scale(1.1);
}

section.menuList button.disabled:hover::before {
  transform: none;
}

section.menuList hr {
  margin: 0.5em 0;
  width: 100%;
  border: none;
  border-top: 0.25em dotted rgba(255, 255, 255, 0.5);
}

.hexylpediaFrame {
  position: absolute;
  inset: 0;
  padding-top: calc(1em + var(--button-size));
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.hexylpediaFrame nav {
  padding-left: var(--button-size);
}

.hexylpediaFrame nav.withTitle {
  background: var(--background);
  box-shadow: 0 0 0.5em var(--background);
}

.hexylpediaFrame nav button {
  flex: 0 0 auto;
}

.hexylpediaFrame nav span {
  flex: 0 1 100%;
  font-size: 1.75em;
  text-align: center;
  text-shadow: 0 0.05em 0 rgba(255, 255, 255, 0.6);
}

.hexylpediaContent {
  flex: 0 1 100%;
  position: relative;
}

.hexylpediaContent .filters {
  padding-block: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}

.hexylpediaContent .filters button {
  flex: 0 1 100%;
  padding: 0.375em;
  font-family: inherit;
  font-size: 1.25em;
  background: none;
  border: none;
  border-radius: 10ex;
  color: inherit;
  cursor: pointer;
}

.hexylpediaContent .filters button:hover,
.hexylpediaContent .filters button:focus {
  background: rgba(255, 255, 255, 0.2);
}

.hexylpediaContent .filters button.selected {
  background: white;
  color: var(--background);
}

.cellList {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  row-gap: 0.5em;
}

.cellList button {
  border: none;
  background: none;
  outline: none;
  font-size: 2.5em;
  cursor: pointer;
}

.cell {
  position: relative;
  font: var(--fa-font-solid);
  display: grid;
  place-content: center;
}

.cell::before {
  color: var(--cell-background);
  background: var(--cell-background);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0.01em 0 rgba(0, 0, 0, 0.3);
  font-size: 1.5em;
  content: "\f312";
}

.cell::after {
  position: absolute;
  inset: 0;
  display: grid;
  place-content: center;
  content: attr(data-symbol);
  font-size: var(--cell-scale);
}

.hexylpediaEntry {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1em;
  height: 100%;
}

.cellCost {
  position: absolute;
  left: 0;
  top: 0.025em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25em;
  min-width: var(--button-size);
  height: var(--button-size);
  padding-block: calc(0.5rem * var(--scale));
  padding-inline: 0.5em;
  font-size: 1.625em;
  z-index: 11;
}

.cellCost::before {
  font: var(--fa-font-solid);
  content: "\f312";
}

.cellCost::after {
  content: attr(data-cost);
}

.cellType {
  /* padding-top: 2em; */
  font-size: 2.25em;
  text-align: center;
  text-shadow: 0 0.05em 0 rgba(255, 255, 255, 0.6);
}

.bigCell {
  font-size: 10em;
  /* padding-top: 0.375em; */
}

.cellTagList {
  box-sizing: border-box;
  /* position: absolute;
  left: 0;
  top: calc(var(--button-size) + 1rem * var(--scale));
  width: 100%; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5em 0.5em;
  padding: 1em 1em 0;
  /* background: var(--background);
  box-shadow: 0 0 0.5em var(--background); */
  /* z-index: 11; */
}

.cellTagList:empty {
  display: none;
}

.cellTag {
  padding: 0.25em 0.75em;
  background: rgba(255, 255, 255, 0.6);
  color: var(--playerBackground-color);
  border-radius: 0.1em;
}

.cellTag:first-child {
  background: var(--player-color);
}

.cellDescription {
  /* padding: 3.5em 1em 2em; */
  padding-inline: 1em;
  font-size: 1.25em;
  line-height: 1.5;
  text-align: center;
  text-wrap: balance;
}

.cellDescription:empty {
  display: none;
}
